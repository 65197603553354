import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom';
import { AppBar, Toolbar, Typography, Button, Box, Container, Checkbox, FormControlLabel } from '@mui/material';


import ProductCard from './components/ProductCard';
import { ArrowBackIos, ArrowForwardIos } from '@mui/icons-material';
import Footer from './Footer';

const apiUrl = process.env.NODE_ENV === 'production'
    ? process.env.REACT_APP_API_URL_PRODUCTION
    : process.env.REACT_APP_API_URL;


function App() {
    return (
        <Router>
            <AppBar position="static" sx={{ backgroundColor: '#4b4c4c' }}>
                <Toolbar
                    sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        gap: 1,
                        flexWrap: 'wrap', // 자동 줄바꿈 설정
                    }}
                >
                    <img
                        src="/images/logo.png"
                        alt="Company Logo"
                        style={{ transform: 'scale(0.6)', marginRight: 2 }}
                    />
                    <Button
                        variant="contained"
                        sx={{
                            backgroundColor: '#888888',
                            color: 'white',
                            '&:hover': { backgroundColor: '#115293' },
                            minWidth: { xs: '30%', sm: 120 }, // 화면 크기에 따라 버튼 너비 조정
                            flexGrow: 1, // 버튼이 가로로 자동 확장되도록 설정
                        }}
                        component={Link}
                        to="/"
                    >
                        HOME
                    </Button>
                    <Button
                        variant="contained"
                        sx={{
                            backgroundColor: '#888888',
                            color: 'white',
                            '&:hover': { backgroundColor: '#2e7d32' },
                            minWidth: { xs: '30%', sm: 120 },
                            flexGrow: 1,
                        }}
                        component={Link}
                        to="/products"
                    >
                        PRODUCTS
                    </Button>
                    
                </Toolbar>
            </AppBar>
            <Container sx={{ mt: 4, paddingBottom: '120px' }}>
                <Routes>
                    <Route path="/" element={<About />} />
                    <Route path="/products" element={<Products />} />
                    <Route path="/contact" element={<Contact />} />
                </Routes>
            </Container>
            <Footer />
        </Router>
    );
}


function About() {

    var arstr = [
        ["농기계를 넘어 IT 솔루션 제품으로", "최고의 농기계를 넘어 Telematics를 기반으로 농기계 관제 서비스, 자율주행 농작업 솔루션을 제공합니다."],
        ["다양한 환경에 맞는이동수단의 진화", "UTV 등 특수하고 다양한 환경에서 최적의 성능을 이끌어내는 진화된 Mobility를 만들어 갑니다."],
        ["농업용 로봇에서부터 확장형까지", "농업용·비농업용 플랫폼 모듈화 전략을 통해 농업과 일상 작업 환경의 미래를 새롭게 바꿔가고 있습니다."],
    ];


    return (



        <Box sx={{ backgroundColor: '#f5f5f5', borderRadius: 2, p: { xs: 2, md: 4 } }}>
            <Typography variant="h4" gutterBottom>
                지속 가능한 농업을 위한 지샘의 도전
            </Typography>
            <Typography variant="body1" gutterBottom>
                농업 발전을 위한 혁신적인 기술과 솔루션, 지샘이 미래를 여는 농업의 파트너가 되어드립니다.
            </Typography>
            <Box sx={{ display: 'flex', flexDirection: { xs: 'column', sm: 'row' }, gap: 2, mt: 4 }}>
                {[1, 2, 3].map((item) => (
                    <Box key={item} sx={{ width: { xs: '100%', sm: '30%' }, backgroundColor: '#fff', borderRadius: 2, boxShadow: 3, p: 2, display: 'flex', flexDirection: 'column', justifyContent: 'space-between', height: { xs: 'auto', sm: 450 } }}>
                        <Box>
                            <Typography variant="h6" gutterBottom>
                                {arstr[item-1][0]}
                            </Typography>
                            <Typography variant="body2">
                                {arstr[item - 1][1]}
                            </Typography>
                        </Box>
                        <img
                            src={`/images/p${item}.jpg`}
                            alt={`Agriculture ${item}`}
                            style={{ width: '100%', marginTop: 10, borderRadius: 8 }}
                        />
                    </Box>
                ))}
            </Box>
        </Box>
    );
}



function Products() {
    const [products, setProducts] = React.useState([]);
    const [page, setPage] = React.useState(1);
    const [totalPages, setTotalPages] = React.useState(1);
    const [selectedProduct, setSelectedProduct] = React.useState(null);
    const [imageIndex, setImageIndex] = React.useState(0); // 이미지 인덱스 추가

    
    const [selectedCategory, setSelectedCategory] = useState('all');
    const [sortOption, setSortOption] = useState(null);


    const maxPageButtons = 5; // 표시할 페이지 버튼의 최대 개수
    
    React.useEffect(() => {
        console.log("API 호출 - 페이지, 카테고리, 정렬 옵션:", page, selectedCategory, sortOption);

        axios.get(apiUrl, {
            params: {
                page,
                category: selectedCategory !== 'all' ? selectedCategory : undefined,
                sort: sortOption || undefined
            }
        })
            .then((response) => {
                if (response.data && response.data.products) {
                    setProducts(response.data.products); // 서버에서 받은 데이터를 그대로 사용
                    setTotalPages(Math.ceil(response.data.total / 20));

                    console.log("받아온 데이터:", response.data.products); // 데이터 확인

                } else {
                    setProducts([]);
                    console.log("없다!");
                }
            })
            .catch((error) => {
                console.error('Error fetching products:', error);
            });
    }, [page, selectedCategory, sortOption]);
    const handlePageChange = (newPage) => {
        if (newPage >= 1 && newPage <= totalPages) {
            setPage(newPage);
        }
    };

    const handleNextImage = () => {
        if (selectedProduct && selectedProduct.ImageArray) {
            setImageIndex((prevIndex) => (prevIndex + 1) % selectedProduct.ImageArray.length);
        }
    };

    const handlePreviousImage = () => {
        if (selectedProduct && selectedProduct.ImageArray) {
            setImageIndex((prevIndex) => (prevIndex - 1 + selectedProduct.ImageArray.length) % selectedProduct.ImageArray.length);
        }
    };


    const handleCategoryChange = (category) => {
        setSelectedCategory(category);
    };

    const handleSortOption = (option) => {
        setSortOption(option);
    };


    const getPageNumbers = () => {
        const pages = [];
        const startPage = Math.max(1, page - Math.floor(maxPageButtons / 2));
        const endPage = Math.min(totalPages, startPage + maxPageButtons - 1);

        for (let i = startPage; i <= endPage; i++) {
            pages.push(i);
        }
        return pages;
    };
    

    const filteredProducts = React.useMemo(() => {
        if (selectedCategory === 'all') {
            return products;
        }
        return products.filter((product) => product.category === selectedCategory);
    }, [products, selectedCategory]);
    return (
        <Box>
            {selectedProduct ? (
                <Box sx={{ paddingBottom: '100px' }}>
                    <Button
                        onClick={() => setSelectedProduct(null)}
                        variant="contained"
                        sx={{
                            backgroundColor: '#1976d2',
                            color: 'white',
                            '&:hover': { backgroundColor: '#115293' },
                            mt: 2,
                            px: 4,
                            py: 1,
                            borderRadius: '20px',
                            fontWeight: 'bold'
                        }}
                    >
                        목록보기
                    </Button>
                    <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: 2 }}>
                        <img
                            src={selectedProduct.ImageArray[imageIndex]}
                            alt={`Product ${imageIndex + 1}`}
                            style={{ width: '100%', maxWidth: 600, height: 'auto' }}
                        />
                        <Typography variant="body1" sx={{ mt: 1 }}>{`${imageIndex + 1} / ${selectedProduct.ImageArray.length}`}</Typography>
                        <Box sx={{ display: 'flex', gap: 2, mt: 2 }}>
                            <Button onClick={handlePreviousImage}><ArrowBackIos /></Button>
                            <Button onClick={handleNextImage}><ArrowForwardIos /></Button>
                        </Box>
                        <Box sx={{ textAlign: 'center', mt: 2, width: '100%', maxWidth: 600 }}> {/* 이미지 크기와 동일한 maxWidth 설정 */}
                            <Typography variant="h4" gutterBottom>{selectedProduct.Title}</Typography>
                            <Box
                                component="table"
                                sx={{
                                    width: '100%',
                                    borderCollapse: 'collapse',
                                    '& td, & th': {
                                        border: '1px solid #ddd',
                                        padding: '8px',
                                    },
                                    '& th': {
                                        width: { xs: '50%', sm: '40%', md: '30%' },
                                        backgroundColor: '#f2f2f2',
                                        textAlign: 'left',
                                        verticalAlign: 'top',
                                    },
                                    '& td': {
                                        width: { xs: '50%', sm: '60%', md: '70%' },
                                    }
                                }}
                            >
                                <tbody>
                                    {selectedProduct.list && selectedProduct.list.map((item, index) => (
                                        <tr key={index}>
                                            <th>{item.key}</th>
                                            <td dangerouslySetInnerHTML={{ __html: item.value }}></td>
                                        </tr>
                                    ))}
                                </tbody>
                            </Box>
                        </Box>
                    </Box>
                </Box>
            ) : (
                <Box>
                        <Box>
                            <Typography variant="h4" gutterBottom>중고기계 목록</Typography>
                            {/* 체크 버튼 */}
                            <Box>
                                <FormControlLabel
                                    control={<Checkbox checked={selectedCategory === 'all'} onChange={() => handleCategoryChange('all')} />}
                                    label="전체"
                                />
                                <FormControlLabel
                                    control={<Checkbox checked={selectedCategory === '트렉터'} onChange={() => handleCategoryChange('트렉터')} />}
                                    label="트렉터"
                                />
                            </Box>

                            {/* 정렬 버튼 */}
                            <Box sx={{ display: 'flex', gap: 2, mt: 2 }}>
                                <Button
                                    variant={sortOption === 'price' ? 'contained' : 'outlined'}
                                    onClick={() => handleSortOption('price')}
                                >
                                    가격순
                                </Button>
                                <Button
                                    variant={sortOption === 'date' ? 'contained' : 'outlined'}
                                    onClick={() => handleSortOption('date')}
                                >
                                    제조날짜순
                                </Button>
                            </Box>

                            {/* 제품 리스트 */}
                            <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 2, mt: 4 }}>
                                {filteredProducts.map((product) => (
                                    <ProductCard
                                        key={product._id}
                                        product={product}
                                        onClick={() => setSelectedProduct(product)}
                                    />
                                ))}
                            </Box>
                        </Box>
                    
                    <Box sx={{ display: 'flex', justifyContent: 'center', mt: 4, gap: 1 }}>
                        <Button onClick={() => handlePageChange(page - 1)} disabled={page === 1}>
                            이전
                        </Button>
                        {getPageNumbers().map((pageNumber) => (
                            <Button
                                key={pageNumber}
                                onClick={() => handlePageChange(pageNumber)}
                                variant={pageNumber === page ? "contained" : "outlined"}
                            >
                                {pageNumber}
                            </Button>
                        ))}
                        <Button onClick={() => handlePageChange(page + 1)} disabled={page === totalPages}>
                            다음
                        </Button>
                    </Box>
                    <Typography sx={{ textAlign: 'center', mt: 2 }}>
                        {`Result ${(page - 1) * 20 + 1} - ${Math.min(page * 20, totalPages * 20)} of ${totalPages * 20}`}
                    </Typography>
                </Box>
            )}
        </Box>
    );
}




function Contact() {
    return (
        <Box>
            <Typography variant="h4" gutterBottom>
                연락처
            </Typography>
            <Typography variant="body1">담당자: 박수호</Typography>
            <Typography variant="body1">핸드폰: +82 10-3332-5889</Typography>
            <Typography variant="body1">이메일: gsem@gmail.com</Typography>
        </Box>
    );
}

export default App;
