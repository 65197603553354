import React from 'react';
import { Box, Typography } from '@mui/material';

function ProductCard({ product, onClick }) {
    return (
        <Box
            sx={{
                width: { xs: '100%', sm: '45%', md: '30%' }, // 반응형 너비 설정
                backgroundColor: '#fff',
                borderRadius: 2,
                boxShadow: 3,
                p: 2,
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-between',
                height: 450,
                cursor: 'pointer',
                mb: 2 // 반응형 여백 추가
            }}
            onClick={onClick}
        >
            <Box>
                <Typography variant="h6" gutterBottom>
                    {product.Title}
                </Typography>
                <Typography variant="body2">
                    제조년도: {product.birthday}
                </Typography>

                <Typography variant="body2">
                    운행시간: {product.Operating}
                </Typography>


                <Typography variant="body2">
                    유로: {new Intl.NumberFormat('ko-KR').format(product.price)} 
                </Typography>
                <Typography variant="body2">
                    원화: {new Intl.NumberFormat('ko-KR').format(product.pricekr)} 원
                </Typography>

                <Typography variant="body2">
                    국가: {product.country}
                </Typography>
                <Typography variant="body2">
                    제조사: {product.Manufacturer}
                </Typography>
            </Box>
            <img
                src={product.Image}
                alt={product.Title}
                style={{ width: '100%', marginTop: 10, borderRadius: 8 }}
            />
        </Box>

    );
}

export default ProductCard;
